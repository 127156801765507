
































import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Blog, WebsiteLevel } from 'client-website-ts-library/types';
import { BlogFilter, BlogOrderBy } from 'client-website-ts-library/filters';
import { Component, Mixins } from 'vue-property-decorator';
import Blogs from '@/components/Blogs.vue';

@Component({
  components: {
    Blogs,
  },
})
export default class BlogDetails extends Mixins(AsyncView) {
  public blog: Blog | null = null;

  public filter: BlogFilter | null = null;

  mounted() {
    const blogId = this.$route.params.id;

    this.filter = new BlogFilter({
      SearchLevel: WebsiteLevel.Office,
      SearchGuid: Config.API.WebsiteId,
      OrderByStatements: [BlogOrderBy.CreateDateDesc],
    });

    API.Blogs.Get(blogId).then((blog) => {
      this.blog = blog;
      this.$emit('updateView');
    });
  }

  stripHtml(html: string, limit: number): string {
    const tmp = document.createElement('div');

    tmp.innerHTML = html;

    const text = tmp.innerText;

    return text.length < (limit * 1.2) ? text : `${tmp.innerText.substring(0, limit)}...`;
  }

  public head(): RouteMeta {
    return {
      title: `${this.blog?.Title} - ${this.blog?.Subtitle}`,
      description: this.stripHtml(this.blog?.Paragraph1 ?? '', 100),
      image: this.blog?.Images.length ? this.blog?.Images[0].Preview.Url : undefined,
    };
  }

  handleError(err: Response) {
    if (err.status === 403) {
      this.$router.push({
        path: '/blogs',
        query: {
          next: `/blogs/${this.$route.params.id}`,
        },
      });
    }
  }
}
